<div class="modal-body">
	<pk-broker-faq-search-modal-feedback
		*ngIf="modalView === 'feedback'"
		[modalView]="modalView"
		[selectedFeedback]="selectedFeedback"
		[criteria]="criteria"
		[faqResult]="faqResult"
		(backClicked)="back()">
	</pk-broker-faq-search-modal-feedback>
	<div class="container-fluid search-panel" *ngIf="modalView !== 'feedback'">
		<pk-broker-loader [show]="loading"></pk-broker-loader>
		<ng-container *ngIf="!loading">
			<div class="row modal-top">
				<div class="col pt-3 pb-2 pl-3">
					<a href="javascript:void(0)" class="go-back" (click)="back()" *ngIf="selectedFaqArticle">
						<fa-icon [icon]="['fas', 'chevron-left']"></fa-icon>Back
					</a>
					<button type="button" class="close" id="closeButton" (click)="activeModal.hide()" aria-label="Close">
						<span aria-hidden="true"><fa-icon [icon]="['fas', 'times']"></fa-icon></span>
					</button>
				</div>
			</div>
			<div class="row modal-top mb-4">
				<div class="col form-element-container">
					<form [formGroup]="formGroup" autocomplete="off">
						<label for="searchFAQ">How can we help you?</label>
						<input
							type="text"
							class="form-control"
							id="searchFAQ"
							placeholder="Start typing your search..."
							autocomplete="off"
							formControlName="criteria" />
						<span class="form-symbol"><fa-icon [icon]="['fas', 'search']"></fa-icon></span>
						<p class="mb-0 mt-1 font-size-075 white-text">Please use at least three characters in your search</p>
					</form>
				</div>
			</div>
			<pk-broker-faq-search-modal-related-articles *ngIf="modalView === 'related-articles'" [faqResult]="faqResult" [faqArticle]="selectedFaqArticle">
			</pk-broker-faq-search-modal-related-articles>
			<ng-container *ngIf="modalView === 'search'">
				<p *ngIf="loadingResults"><pk-broker-loader [show]="loadingResults"></pk-broker-loader></p>
				<ng-container *ngIf="!loadingResults">
					<div class="row" *ngIf="!faqResult">
						<div class="col mb-5">
							<h4>Need help?</h4>
							<p>Start by searching our Frequently Asked Questions at the top. Many common issues that come up have been addressed there.</p>
						</div>
					</div>
					<ng-container *ngIf="faqResult">
						<div class="row">
							<div class="col">
								<p class="text-center" *ngIf="!resultCount">Your search yielded no results. Please try another search.</p>
								<ng-container *ngFor="let siteSectionSearch of faqResult.siteSectionSearchResultsGrouped">
									<h4>{{ siteSectionSearch.question }}</h4>
									<ul>
										<li *ngFor="let faqSearch of siteSectionSearch.answers">
											<a
												class="faq-answer"
												[routerLink]="[loggedInUser.agentId ? faqSearch.brokerRoute : (faqSearch.adminRoute ?? faqSearch.brokerRoute)]"
												target="_blank"
												[innerHtml]="faqSearch.answer">
											</a>
										</li>
									</ul>
								</ng-container>
								<ng-container *ngIf="faqResult.faqSearchResults?.length">
									<h4>Potential answers to your question</h4>
									<ol>
										<li *ngFor="let faqSearch of faqResult.faqSearchResults">
											<a class="faq-answer" href="javascript:void(0)" (click)="chooseFaqArticle(faqSearch)">{{ faqSearch.question }}</a>
										</li>
									</ol>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</div>
</div>
<div class="modal-footer" *ngIf="isAdminOrParent && faqResult && modalView !== 'feedback'">
	<div class="container-fluid feedback-section">
		<div class="row">
			<div class="col text-center">
				<p class="mb-2 semibold-text blue-text">Is this helpful?</p>
				<p class="mb-0">
					<a href="javascript:void(0)" class="left-link" (click)="answerFeedback('positive')">Yes</a>
					<a href="javascript:void(0)" class="right-link" (click)="answerFeedback('negative')">No</a>
				</p>
			</div>
		</div>
	</div>
</div>
